/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Image, Subtitle } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1unftk --style3 --full" anim={""} name={"wstęp"} animS={"5"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim6 --anim-s5" anim={"6"} animS={"5"}>
              
              <Title className="title-box fs--102 lh--1 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: rgb(255, 255, 255);\">Ślusarstwo Kraków</span>"}>
              </Title>

              <Text className="text-box mt--25" style={{"maxWidth":764}} content={"<span style=\"color: rgb(255, 255, 255);\">Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować.</span>"}>
              </Text>

              <Button className="btn-box" content={"Oferta usług"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--50" name={"informacje"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box fs--62" content={"Od dorabiania kluczy do otwierania drzwi awaryjnych"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 mt--50" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/152/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/152/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/152/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/152/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/152/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/152/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Title className="title-box" content={"Otwieranie drzwi"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":321}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/152/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/152/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/152/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/152/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/152/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/152/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Title className="title-box" content={"Zakładanie zamków"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":321}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":480}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/152/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/152/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/152/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/152/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/152/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/152/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Title className="title-box" content={"Serwis i konserwacja"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":321}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1unftk pb--60 pt--60" name={"kontakt"} layout={"l1"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/152/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="pb--10 pt--10">
              
              <Title className="title-box fs--62 lh--1 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: rgb(255, 255, 255);\">Zatrzasnąłeś drzwi?</span>"}>
              </Title>

              <Button className="btn-box" content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"stopka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Ślusarstwo Kraków</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Pańska 18</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">+48 797 811 2X0<br>info@twoje-stronyy.com</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">REGON: 12345678<br>Utworzone przez<a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}